@import-normalize;

html,
body {
  height: 100%;
}

body {
  margin: 0;
}
